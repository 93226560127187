<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-1 gap-1 items-center">
      <div class="flex w-75 items-center ml-1 mr-1 pr-3">
        <div class="competency-label rounded-full  mr-1.5" :style="{ 'background-color': `${source.color}` }"></div>
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4 mr-1">
        <p class="flex flex-grow text-md text-grey-dark-1 font-semibold">
          {{ source.name.replaceAll("&nbsp;", " ") }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5">
        <p class="text-md text-grey-dark-2">
          <ExpandMore :data="subComps" showHideButton />
        </p>
      </div>
      <div class="flex flex-grow w-1/5 ml-auto">
        <p class="text-md text-grey-dark-2">
          {{ source.survey.replaceAll("&nbsp;", " ") }}
        </p>
      </div>
      <div class="flex flex-grow w-1/10 justify-right">
        <p class="text-md text-grey-dark-2" :class="source.active ? 'text-green' : 'text-red'">
          {{ source.active ? "Active" : "Inactive" }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/10 justify-left">
        <div class="flex gap-1">
          <IconButton :icon="view" :onClick="() => viewCompetency()" class="iconButton border border-grey-border" />
          <IconButton :icon="edit" :onClick="() => editCompetency()" class="iconButton border border-grey-border" />
        </div>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Button from "../../../../components/Button/Button.vue";
import ExpandMore from "../../../../components/ExpandMore/ExpandMore.vue";
import IconButton from "../../../../components/IconButton/IconButton.vue";
import edit from "@/assets/img/icons/edit.svg";
import view from "@/assets/img/icons/view.svg";
import { getLatestVersion, getLatestPublishedVersion } from '../Common/globalEditUtils';
import {
  displayGoToDraft,
  displayCreateNewVersion,
  displayGoToVersionItBelongs
} from '../Common/globalEditUtils';

export default {
  components: { IconButton, Button, ExpandMore },
  data: () => ({ edit, view }),
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
  },
  computed: {
    subComps() {
      return this.source.subCompetencies.map((item) => item.name);
    },
  },
  methods: {
    ...mapActions({
      getCompetencyById: "products/getCompetencyById",
      getProductById: "companies/getProductById",
      openModal: "modals/openModal",
      showError: 'alerts/showError'
    }),
    async viewCompetency() {
      await this.getCompetencyById(this.source.id).then(() =>
        this.$router.push(
          `/products/global/view-competency/${this.source.id}`
        )
      );
    },
    async editCompetency() {
      var product = await this.getProductById({ id: this.source.productId });
      let latestVersion = getLatestVersion(product.productVersions);
      let latestPublishedVersion = getLatestPublishedVersion(product.productVersions, product.draftVersion);
      if (product.draftVersion !== null && product.draftVersion !== latestVersion) {
        this.showError({
          messageHeader: 'Invalid competency',
          message:
            'Something went wrong. Select another competency or contact the administrator.',
        });
      } else {
        this.openModal({
          modal: "editJobRoleOrCompetencyModal",
          props: {
            isJobRole: false,
            productId: product.id,
            productName: product.name,
            displayGoToDraft: displayGoToDraft(latestVersion, product.draftVersion),
            displayGoToVersionItBelongs: displayGoToVersionItBelongs(product.currentVersion, product.draftVersion),
            displayCreateNewVersion: displayCreateNewVersion(product.draftVersion),
            latestVersion: latestVersion,
            latestPublishedVersion: latestPublishedVersion,
            currentVersion: product.currentVersion,
            draftVersion: product.draftVersion
          },
        });
      }
    },

  },
};
</script>
<style lang="scss">
.competency-label {
  height: 13px;
  width: 13px;
}
</style>
