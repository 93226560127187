<template>
  <div>
    <p
      class="text-xl text-grey-light font-medium font-headers mb-3 font-bold text-grey-dark-3"
    >
      Competencies
    </p>
    <CompetenciesTable />
  </div>
</template>

<script>
import CompetenciesTable from "../components/CompetenciesTable";
export default {
  name: "CompetenciesView",
  components: { CompetenciesTable },
};
</script>
